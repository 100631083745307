export default grapesjs.plugins.add('TeaserLarge', (editor, options) => {
	editor.BlockManager.add('TeaserLarge', {
		category: 'Commerce',
		label: 'Teaser Large',
		attributes: { class: 'fa fa-gift' },
		content: `<div class="w-100 mb-5 hdm-bg-secondary teaser-large-container" data-gjs-type="TeaserLarge" data-initial-state="true">			
			Component preview: configure your large teaser component!
			<br /> 
			<img class="img-fluid" src="` + CCRZ.pagevars.themeBaseURL + `images/ccc-preview-teaser-large.png" style="margin: auto; display: block;" data-gjs-removable="false" data-gjs-draggable="false" data-gjs-badgable="false" data-gjs-stylable="false" data-gjs-highlightable="false" data-gjs-copyable="false" data-gjs-resizable="false" data-gjs-editable="false" data-gjs-layerable="false" data-gjs-selectable="false" data-gjs-hoverable="false" />
		</div>`
	});

	// Get DomComponents module
	var comps = editor.DomComponents;
	
	// Get the model and the view from the default Component type
	var defaultType = comps.getType('default');
	var defaultModel = defaultType.model;
	var defaultView = defaultType.view;

	// start configuration Teaser-Tile-Big
	comps.addType('TeaserLarge', {
		// Define the Model
		model: defaultModel.extend({
			// Extend default properties
			defaults: Object.assign(
				{},
				defaultModel.prototype.defaults, {
					// Can't drop other elements inside it
					droppable: false,
					// Traits (Settings)
					traits: [
						{
							type: 'text', // Type of the trait
							label: 'Image path', // The label you will see in Settings
							name: 'imgSrc', // The name of the attribute/property to use on component
						},
						{
							type: 'text', // Type of the trait
							label: 'Image title', // The label you will see in Settings
							name: 'imgTitle', // The name of the attribute/property to use on component
						},
						{
							type: 'text', // Type of the trait
							label: 'Headline first', // The label you will see in Settings
							name: 'headlineFirst', // The name of the attribute/property to use on component
						},
						{
							type: 'text', // Type of the trait
							label: 'Headline second', // The label you will see in Settings
							name: 'headlineSecond', // The name of the attribute/property to use on component
						},
						{
							type: 'text', // Type of the trait
							label: 'Content text', // The label you will see in Settings
							name: 'contentText', // The name of the attribute/property to use on component
						}, {
							type: 'text', // Type of the trait
							label: 'Primary button text', // The label you will see in Settings
							name: 'btnPriTxt', // The name of the attribute/property to use on component
						},
						{
							type: 'text', // Type of the trait
							label: 'Primary button link', // The label you will see in Settings
							name: 'btnPriHref', // The name of the attribute/property to use on component
						},
						{
							type: 'text', // Type of the trait
							label: 'Secondary button text', // The label you will see in Settings
							name: 'btnSecTxt', // The name of the attribute/property to use on component
						},
						{
							type: 'text', // Type of the trait
							label: 'Secondary button link', // The label you will see in Settings
							name: 'btnSecHref', // The name of the attribute/property to use on component
						},
						{
							type: 'text', // Type of the trait
							label: 'Is Visible From', // The label you will see in Settings
							name: 'cccStartDate', // The name of the attribute/property to use on component
						},
						{
							type: 'text', // Type of the trait
							label: 'Is Visible To', // The label you will see in Settings
							name: 'cccEndDate', // The name of the attribute/property to use on component
						}
					],
					attributes: {
						imgSrc: '',
						imgTitle: '',
						headlineFirst: '',
						headlineSecond: '',
						contentText: '',
						btnPriTxt: '',
						btnPriHref: '',
						btnSecTxt: '',
						btnSecHref: '',
						cccStartDate: '',
						cccEndDate: ''
					}
				}
			),
			init() {
				this.on('change:attributes:imgSrc', this.handleTraitChange);
				this.on('change:attributes:imgTitle', this.handleTraitChange);
				this.on('change:attributes:headlineFirst', this.handleTraitChange);
				this.on('change:attributes:headlineSecond', this.handleTraitChange);
				this.on('change:attributes:contentText', this.handleTraitChange);
				this.on('change:attributes:btnPriTxt', this.handleTraitChange);
				this.on('change:attributes:btnPriHref', this.handleTraitChange);
				this.on('change:attributes:btnSecTxt', this.handleTraitChange);
				this.on('change:attributes:btnSecHref', this.handleTraitChange);
				this.on('change:attributes:cccStartDate', this.handleTraitChange);
				this.on('change:attributes:cccEndDate', this.handleTraitChange);
			},
			handleTraitChange() {
				CCRZ.testaus = this;
				this.attributes.content = this.generateHTML(false);
				this.view.el.innerHTML = this.generateHTML(false);
				$(this.view.el).removeAttr('data-initial-state');
				delete this.view.attr['data-initial-state'];
				delete this.attributes.attributes['data-initial-state'];
			},
			toHTML: function () {
				return this.generateHTML(true);
			},
			generateHTML(includeParentDOMElement) {
				if (typeof includeParentDOMElement == 'undefined') {
					includeParentDOMElement = false;
				}

				let elementString = '';
				let timedAttributes = '';

				if (includeParentDOMElement) {
					if (this.getAttributes().cccStartDate && this.getAttributes().cccEndDate) {
						timedAttributes = ' cccStartDate="' + this.getAttributes().cccStartDate + '" cccEndDate="' +  this.getAttributes().cccEndDate + '"';
					}
					elementString = elementString + '<div class="w-100 hdm-bg-secondary teaser-large-container" data-gjs-type="TeaserLarge"' + timedAttributes + '>';
				}

				// elementString += '<div class="w-100 hdm-bg-secondary teaser-large-container">';
				elementString += 	'<div class="container-fluid py-4">';
				elementString += 		'<div class="row">';
				elementString += 			'<div class="col-12 col-lg-6 mb-5 mb-lg-0 pl-xl-0 d-flex justify-content-center">';
				elementString += 				'<img class="img-fluid" style="object-fit: cover;" src="' + this.getAttributes().imgSrc + '" alt="' + this.getAttributes().imgTitle + '" title="' + this.getAttributes().imgTitle + '" />';
				elementString += 			'</div>';
				elementString += 			'<div class="col-12 col-lg-6 pr-xl-0 d-flex flex-column align-items-start justify-content-center">';
				if (this.getAttributes().headlineFirst)
				elementString += 				'<p class="teaser-large-firstheader">' + this.getAttributes().headlineFirst + '</p>';
				if (this.getAttributes().headlineSecond)
				elementString += 				'<p class="teaser-large-secondheader">' + this.getAttributes().headlineSecond + '</p>';
				elementString += 				'<div class="slider-underline"></div>';
				elementString += 				'<div class="slider-content">';
				elementString += 					'<div class="mb-4">' + this.getAttributes().contentText + '</div>';
				if (this.getAttributes().btnPriTxt)
				elementString += 					'<a class="btn btn-primary btn-lg mr-3" href="' + this.getAttributes().btnPriHref + '">' + this.getAttributes().btnPriTxt + '</a>';
				if (this.getAttributes().btnSecTxt)
				elementString += 					'<a class="btn btn-primary btn-secondary btn-lg bg-transparent" href="' + this.getAttributes().btnSecHref + '">' + this.getAttributes().btnSecTxt + '</a>';
				elementString += 				'</div>';
				elementString += 			'</div>';
				elementString += 		'</div>';
				elementString += 	'</div>';
				// elementString += '</div>';


				if (includeParentDOMElement) {
					elementString += '</div>';
				}

				return elementString;
			}
		},
			// The second argument of .extend are static methods and we'll put inside our
			// isComponent() method. As you're putting a new Component type on top of the stack,
			// not declaring isComponent() might probably break stuff, especially if you extend
			// the default one.
			{
				isComponent(el) {
					if (el.getAttribute && el.getAttribute('data-gjs-type') == 'TeaserLarge') {
						return {
							type: 'TeaserLarge'
						};
					}
				}
			}
		)
	});
});
